<template>
  <div>
    <modal-exempt-statement></modal-exempt-statement>
    <b-modal
      id="modal-login"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      scrollable
      title="登入"
      title-class="font-weight-bolder"
      body-class="common_modal "
      hide-footer
      centered
      size="sm"
    >
      <!-- form -->
      <validation-observer ref="loginValidation" #default="{ invalid }">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-form class="auth-form mt-2" @submit.prevent>
            <!-- name -->
            <b-form-group label="用戶名" label-for="login-name">
              <validation-provider
                #default="{ errors }"
                name="用戶名"
                rules="required"
              >
                <b-form-input
                  id="login-name"
                  v-model="userName"
                  :state="errors.length > 0 ? false : null"
                  name="login-name"
                  placeholder="輸入用戶名"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!--  password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">密碼</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="密碼"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="輸入密碼"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <b-link
                class="float-right"
                :to="{ name: 'auth-forgot-password-v2' }"
              >
                <small>忘記密碼?</small>
              </b-link>
            </b-form-group>
              <div class="d-flex mt-2 mb-1">
                <div class="d-inline-flex align-items-center">
                  <b-form-checkbox
                    v-model="is_readed"
                    id="exempt"
                    class="mr-0"
                    inline
                  />
                  <label 
                    class="mb-0"
                    for="exempt"
                    style="white-space: nowrap"
                    >剔選即表示你同意我們的<a
                      class="link-green"
                      @click="$bvModal.show(`modal-exempt-statement`)"
                      href="javascript:void(0)"
                      >免責聲明</a
                    >。</label
                  >
                </div>
              </div>
              <small v-show="is_submited && !is_readed" class="text-danger">請同意我們的免責聲明</small>

            <b-button
              class="mb-3 btn-login"
              type="submit"
              variant="primary"
              block
              @click="validationForm"
              :disabled="invalid"
            >
              立即登入
            </b-button>
          </b-form>
        </b-overlay>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BLink,
  VBModal,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardBody,
  BCard,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import { required } from "@validations";
import ModalExemptStatement from "./ModalExemptStatement.vue";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BFormCheckbox,
    BLink,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardBody,
    vSelect,
    BCard,
    ValidationProvider,
    ValidationObserver,
    ModalExemptStatement,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userName: "",
      required,
      loading: false,
      is_readed: false,
      is_submited: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-user");
    },
    validationForm() {
      if(this.is_readed){
        this.$refs.loginValidation.validate().then((success) => {
          if (success) {
            this.login();
          }
        });
      }else{
        this.is_submited = true;
      }
    },
    login() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.loading = true;
          useJwt
            .login({
              email: this.userName,
              password: this.password,
            })
            .then((response) => {
              this.loading = false;
              const { userData } = response.data;
              useJwt.setToken(response.data.access_token);
              localStorage.setItem("userData", JSON.stringify(userData));
              this.$ability.update(userData.ability);
              this.$router
                .replace("/dashboard")
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `歡迎 ${userData.name}`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `您已成功登錄!`,
                    },
                  });
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$refs.loginForm.setErrors(error.response.data.error);
                });
            })
            .catch((error) => {
              this.$swal({
                text:
                  (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  "用戶名或密碼不正確!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
    pushRouteTo(route) {
      // if sending path:
      if (typeof route == "string") {
        if (this.$route.path != route) {
          this.$router.push(route);
        }
      } else {
        // if sending a {name: '', ...}
        if (this.$route.name == route.name) {
          if ("params" in route) {
            let routesMatched = true;
            for (key in this.$route.params) {
              const value = this.$route.params[key];
              if (value == null || value == undefined) {
                if (key in route.params) {
                  if (
                    route.params[key] != undefined &&
                    route.params[key] != null
                  ) {
                    routesMatched = false;
                    break;
                  }
                }
              } else {
                if (key in route.params) {
                  if (routes.params[key] != value) {
                    routesMatched = false;
                    break;
                  }
                } else {
                  routesMatched = false;
                  break;
                }
              }
              if (!routesMatched) {
                this.$router.push(route);
              }
            }
          } else {
            if (Object.keys(this.$route.params).length != 0) {
              this.$router.push(route);
            }
          }
        }
      }
    },
  },
  props: {},
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

</style>

