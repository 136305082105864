

<template>
  <b-modal
    id="modal-exempt-statement"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    :ok-disabled="false"
    headerClass="px-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >
    <template #modal-header="{ close }">
      <h3 class="m-0">免責聲明</h3>
      <a @click="close()">
        <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
      </a>
    </template>
    <div class="exempt">
      <p>
        本網站所提供的所有內容僅供參考和一般資訊之用，並不構成任何形式的建議或保證。不保證本網站所提供的內容的準確性、完整性、及時性、適用性、可靠性或合法性。使用者應自行承擔使用本網站內容的風險，並應在必要時尋求專業意見。
      </p>
      <p>
        本網站可能會包含第三方網站的鏈接，但我們不對這些網站的內容或可用性負責。我們不保證這些網站的準確性、完整性、及時性、適用性、可靠性或合法性，並且不會對使用或依賴這些網站所引起的任何損失或損害負責。
      </p>
      <p>
        不保證或聲明由本網站或任何經由本網站傳送給使用者的資訊或電子數據或資料是不含有電腦病毒。
      </p>
      <p>
        我們保留隨時更改、修改、添加或刪除本網站的內容、功能、服務或任何其他方面的權利，而無需事先通知或負責任何人或實體。我們不會對使用或無法使用本網站所引起的任何損失或損害負責。
      </p>
      <p>
        除特別授權外，不得複製或轉載本網站之數據。對於因使用者擅自複製或轉載本網站之數據而引致或與之相關的任何損失、毀壞、損害或任何法律責任，本網站概不承擔任何責任。
      </p>
      <p>
        使用者確認其知悉，在使用本網站提供的服務存在有來自任何其他人的包括威脅性的或非法的內容或行為或對他們權利的侵犯(包括個人資料)的匿名或冒名的信息的風險，使用者需承擔已上風險，對因此導致任何使用者不正當或非法使用本網站提供之服務產生的直接、間接、偶然、懲罰性的損害，不承擔任何責任。
      </p>
      <p>
        網站使用者應自行承擔風險，並且同意就其使用本網站而產生的任何索賠、損失、損害、費用或責任，賠償及使本公司及其附屬公司、合作夥伴、董事、高級管理人員、員工及代理人免於受損害。
      </p>
      <p>
        使用者在使用本網站及其相關服務時，應自行承擔風險，並同意本網站可能會收集、使用、處理、儲存和分享用戶的個人資料，以實現本網站及其相關服務的功能和目的。使用者應確保其提供的個人資料真實、準確和完整，並保護其用戶名和密碼，避免遭到未經授權的訪問或使用。用戶將帳戶密碼告知他人或與他人共享註冊帳戶，導致的任何個人信息洩漏。本網站不承認任何責任。
      </p>
      <p>
        此免責聲明的任何條款，如被認定為無效或無法強制執行，則該條款應被視為可分割的，不影響其它條款的有效性和可強制性。
      </p>
      <p>
        使用者應在使用本網站及其相關服務前仔細閱讀並瞭解本免責聲明及隱私政策的內容。使用本網站及其相關服務即表示使用者已同意本免責聲明及隱私政策的內容。
      </p>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="cancel"
        >
          取消
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
// import vSelect from "vue-select";
// import { useToast } from "vue-toastification/composition";
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import { ref, watch, computed } from "@vue/composition-api";
// import axiosIns from "@/libs/axios";
// import ability from "@/libs/acl/ability";
import {
  BModal,
  BButton,
  BFormInput,
  BTable,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BTable,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BPagination,
  },
  data() {
    return {};
  },
  directives: {
    Ripple,
  },
  methods: {
  },
  setup() {
  },
};
</script>


<style lang="scss">
#modal-exempt-statement{
  p{
    margin-bottom:1.3rem;
  }
}
</style>