<template>
  <div class="auth-wrapper auth-v2">
    <modal-login />
    <b-link class="brand-logo">
      <!-- <vuexy-logo /> -->
      <!-- <img class="d-lg-none" src="~@/assets/images/logo/logo-dark.png"> -->
      <img class="" src="~@/assets/images/logo/logo.png" />
    </b-link>

    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col cols="12" lg="6" class="d-flex align-items-center bg-login-image">
        <!-- Brand logo-->

        <!-- /Brand logo-->
        <div class="bg-login-image__effect__wrapper">
          <img
            src="~@/assets/images/pages/login-bg2.png"
            class="bg-login-image__effect"
          />
        </div>
        <div class="d-none d-lg-block mx-auto session-video">
          <b-col sm="8" lg="7" class="mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold colorWhite mb-1">
              X Data 資料庫搜尋系統
            </b-card-title>
            <b-card-text class="mt-3 colorWhite"> 安全·可靠·最齊 </b-card-text>
            <b-card-text class="mt-2 colorWhite">
              提供一站式搜尋系統，專業分析，AI智能應用， 更準確及快速運作系統
            </b-card-text>

            <b-button
              class="mt-3 colorWhite trigger-login-modal px-3"
              type="submit"
              variant="primary"
              @click="$bvModal.show('modal-login')"
            >
              立即登入
            </b-button>
          </b-col>
        </div>
        <div v-if="false" class="d-none d-lg-block session-video">
          <b-carousel
            id="carousel-1"
            style="text-shadow: 0px 0px 2px #000"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide
              img-src="~@/assets/images/banner/xd.jpeg"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="~@/assets/images/banner/xd1.jpeg"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="~@/assets/images/banner/xd2.jpeg"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="~@/assets/images/banner/xd3.jpeg"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="~@/assets/images/banner/xd4.jpeg"
            ></b-carousel-slide>
          </b-carousel>
          <!-- <video playsinline controls muted loop autoplay>
            <source src="~@/assets/video/v1.mp4" type="video/mp4" />
          </video> -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col cols="12" lg="6" class="d-flex align-items-center p-0">
        <img
          src="~@/assets/images/pages/bg-supplier.jpeg"
          class="w-100 h-100 d-none d-lg-block"
        />
        <b-col sm="8" lg="7" class="mx-auto my-2 d-block d-lg-none">
            <b-card-title title-tag="h2" class="font-weight-bold  mb-1">
              X Data 資料庫搜尋系統
            </b-card-title>
            <b-card-text class="mt-3 "> 安全·可靠·最齊 </b-card-text>
            <b-card-text class="mt-2 ">
              提供一站式搜尋系統，專業分析，AI智能應用， 更準確及快速運作系統
            </b-card-text>

            <b-button
              class="mt-3  trigger-login-modal px-3"
              type="submit"
              variant="primary"
              @click="$bvModal.show('modal-login')"
            >
              立即登入
            </b-button>
          </b-col>
      </b-col>
      <!-- /Login-->

      <!-- mobile video -->
      <b-col cols="12" class="d-lg-none py-2 session-video">
        <img
          src="~@/assets/images/pages/bg-supplier.jpeg"
          class="bg-login-bottom-supplier"
        />
      </b-col>
      <b-col v-if="false" cols="12" class="d-lg-none py-2 session-video">
        <!-- <video playsinline controls muted loop autoplay>
            <source src="~@/assets/video/v1.mp4" type="video/mp4" />
          </video> -->

        <b-carousel
          id="carousel-2"
          style="text-shadow: 0px 0px 2px #000"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide
            img-src="~@/assets/images/banner/xd.jpeg"
          ></b-carousel-slide>
          <b-carousel-slide
            img-src="~@/assets/images/banner/xd1.jpeg"
          ></b-carousel-slide>
          <b-carousel-slide
            img-src="~@/assets/images/banner/xd2.jpeg"
          ></b-carousel-slide>
          <b-carousel-slide
            img-src="~@/assets/images/banner/xd3.jpeg"
          ></b-carousel-slide>
          <b-carousel-slide
            img-src="~@/assets/images/banner/xd4.jpeg"
          ></b-carousel-slide>
        </b-carousel>
        <!-- <video playsinline controls muted loop autoplay>
            <source src="~@/assets/video/v1.mp4" type="video/mp4" />
          </video> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import ModalLogin from "../modal/ModalLogin.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardText,
  BCardTitle,
  BImg,
  BCarousel,
  BCarouselSlide,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BCarousel,
    BCarouselSlide,
    BButton,
    VuexyLogo,
    ModalLogin,
  },
  data() {
    return {
      imgUrl: require("@/assets/images/pages/login-v2.svg"),
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
  mounted() {
    // this.$refs.refVideo.play()
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.session-video {
  // position: absolute;
  // top:0px;
  // left: 50%;
  // transform: translateX(-50%);
  width: 90%;
  // max-width: 820px;
  img,
  video {
    width: 100%;
  }
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}
.carousel-indicators > li {
  border-radius: 50%;
  border: 1px solid transparent;
  margin-right: 5px;
  margin-left: 5px;
  width: 8px;
  height: 8px;
}

@media screen and (max-width: 991px) {
  body .auth-wrapper.auth-v2 .brand-logo {
    top: 4.8rem;
  }
  .auth-wrapper.auth-v2 .auth-inner {
    height: auto;
  }
  .session-video {
    position: static;

    left: 0px;
    transform: translateX(0px);
    bottom: 0;
    width: 100%;
  }

  // .auth-inner{
  //   flex-direction: column;
  // }

  .bg-login-image {
    height: 180px;
    &__effect {
      width: 100%;
      left: auto;
      // bottom: auto;
      right: -40%;
      // top: 0px;
      transform: scale(-1, 1);
    }
  }
}
</style>